@import url("reset.css");
@import url("nav.css");
@font-face {
    font-family: 'emeraldnormal';
    src: url('../fonts/emerald-normal-webfont.woff2') format('woff2'),
         url('../fonts/emerald-normal-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html {
	background-image: url(../images/illustration-bg-grey.png);
	background-size: 100%;
	background-repeat: repeat-y;
	background-attachment: fixed;
}


body {
	font-family: 'Roboto', Helvetica, sans-serif;
	background-color: rgba(50, 50, 50, 0.98);
}

h3 {
	font-size: 60px;
	font-weight: 900;
	color: #434343;
	margin-bottom: 30px;
}

p {
	font-weight: 300;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 20px;
}

.content {
	text-align: center;
}

br.space {
	position: relative;
	content: ' ';
	height: 300px;
	display: block;
	background-color: transparent;
}

/* flip cards */

.card {
  position: relative;
  float: left;
  padding-bottom: 25%;
  width: 25%;
  text-align: center;
  overflow: hidden;
}

.card__front,
.card__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card__front,
.card__back {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
          transition: transform 0.3s;
}

.card__back {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}

.card.effect__click.flipped .card__front {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}

.card.effect__click.flipped .card__back {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
}

.card__front:before {
	opacity: 0;
	content: ' ';
	display: block;
	width: 100%;
	height: 100%;
	background: -moz-radial-gradient(center, ellipse cover, rgba(67,67,67,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
	background: -webkit-radial-gradient(center, ellipse cover, rgba(67,67,67,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
	background: radial-gradient(ellipse at center, rgba(67,67,67,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#43434300', endColorstr='#a6000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */;
	-webkit-transition: opacity 200ms ease-in 100ms;
	-moz-transition: opacity 200ms ease-in 100ms;
	-o-transition: opacity 200ms ease-in 100ms;
	transition: opacity 200ms ease-in 100ms;

}

.card__front:hover {
		transform: scale(1.1);
}

.card__front:hover:before {
	opacity: 1;

}

/* style the content
--------------------------------------------- */

#intro {
	color: #ffffff;
	font-weight: 900;
	padding: 360px 0;
}

#intro h1 {
	font-size: 150px;
}

#intro h2 {
	margin-top: 20px;
	font-size: 30px;
	font-weight: 300;
	margin-bottom: 30px;
	font-family: serif;
	font-style: italic;
	letter-spacing: 1px;
}

#intro p {
	font-weight: 300;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 10px;
}

#card-1 .card__front {
	background-image: url(../images/card-git.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
}

#card-1 .card__back {
	background-color: #ffffff;
}

#card-1 .card__back p {
	font-size: 50px;
	padding-top: 45%;
}

#card-1 .card__back a::before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 50%;
	right: 50%;
	bottom: 0;
	background: #0091B0;
	height: 2px;
	-webkit-transition-property: left, right;
	transition-property: left, right;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

#card-1 .card__back a {
	color: #434343;
	text-decoration: none;
	position: relative;
	z-index: 21;
}

#card-1 .card__back a:hover:before {
	left: 0;
	right: 0;
}

#card-1 .card__back a:hover {
	color: #0091B0;
	-webkit-transition: color 200ms ease-in 100ms;
	-moz-transition: color 200ms ease-in 100ms;
	-o-transition: color 200ms ease-in 100ms;
	transition: color 200ms ease-in 100ms;
}

#card-2 .card__front {
	background-image: url(../images/card-illustrator.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
}

#card-2 .card__back {
	background-image: url(../images/me-sketch.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
}

#card-3 .card__front {
	background-image: url(../images/card-ps.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
}

#card-3 .card__back {
	background-image: url(../images/me.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
}

#card-4 .card__front {
	background-image: url(../images/card-wacom.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
}

#card-4 .card__back p {
	padding-top: 80%;
}

#card-4 .card__back {
	background-image: url(../images/me-picasso.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
}

#slogan-wrapper {
	clear: left;
}

#slogan {
	padding: 80px;
	background-color: #0091B0;
}

#slogan h1 {
	font-size: 55px;
	color: #ffffff;
	font-weight: 300;
}

.text-hover a {
	color: #434343;
	text-decoration: none;
}

.text-hover a:hover {
	color: #0091B0;
	position: relative;
	z-index: 21;
}

.text-hover a:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 50%;
	right: 50%;
	bottom: 0;
	background: #0091B0;
	height: 2px;
	-webkit-transition-property: left, right;
	transition-property: left, right;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.text-hover a:hover:before {
	left: 0;
	right: 0;
}

.text-hover a:hover {
	color: #0091B0;
	-webkit-transition: color 200ms ease-in 100ms;
	-moz-transition: color 200ms ease-in 100ms;
	-o-transition: color 200ms ease-in 100ms;
	transition: color 200ms ease-in 100ms;
}

#portfolio {
	padding-top: 150px;
	padding-bottom: 200px;
	background-color: #fff;
}

#portfolio h4 {
	font-size: 75px;
	font-weight: 900;
	color: #434343;
	margin-bottom: 30px;
}

#portfolio h5 {
	margin-top: 80px;
	font-size: 30px;
	font-weight: 900;
	color: #434343;
	margin-bottom: 30px;
}

#portfolio p {
	color: #434343;
	font-weight: 300;
	padding-left: 10px;
	padding-right: 10px;
}

#portfolio ul::after {
	content: '';
	display: table;
	clear: both;
}

#portfolio ul li {
	position: relative;
	float: left;
	width: 20%;
	padding-bottom: 20%;
	overflow: hidden;
}

#portfolio ul li a {
	color: #fff;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	text-decoration: none;
	position: absolute;
	transition: all 0.3s;
	background-size: 101%;
	background-position: 0 0;
	background-repeat: no-repeat;
}

#portfolio ul a span {
	display: none;
	font-size: 20px;
	font-weight: 900;
	letter-spacing: 1px;
	display: block;
	position: absolute;
	top: 50%;
	width: 100%;
	z-index: 2;
	text-align: center;
	opacity: 0;
	-webkit-transition: all 150ms ease-in 100ms;
	-moz-transition: all 150ms ease-in 100ms;
	-o-transition: all 150ms ease-in 100ms;
	transition: all 150ms ease-in 100ms;
}

#portfolio ul a:hover span {
	opacity: 1;

}

#portfolio ul a:hover {
	transform: scale(1.1);
}

#portfolio ul a::before {
	-webkit-transition: all 150ms ease-in 100ms;
	-moz-transition: all 150ms ease-in 100ms;
	-o-transition: all 150ms ease-in 100ms;
	transition: all 150ms ease-in 100ms;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	display: block;
	content: ' ';
	opacity: 0;
}

#portfolio ul a:hover::before {
	opacity: 1;
}

#portfolio #main-portfolio li:nth-child(1) a {
	background-image: url(../images/oppen.jpg);

}

#portfolio #main-portfolio li:nth-child(2) a {
	background-image: url(../images/mikroaktiv.jpg);
}

#portfolio #main-portfolio li:nth-child(3) a {
	background-image: url(../images/florian.jpg);
}

#portfolio #main-portfolio li:nth-child(4) a {
	background-image: url(../images/kt-despicable.jpg);
}

#portfolio #main-portfolio li:nth-child(5) a {
	background-image: url(../images/kommoden.jpg);
}

#portfolio #main-portfolio li:nth-child(6) a {
	background-image: url(../images/16dager.jpg);
}

#portfolio #main-portfolio li:nth-child(7) a {
	background-image: url(../images/wow-ig.jpg);
}

#portfolio #main-portfolio li:nth-child(8) a {
	background-image: url(../images/go.jpg);
}

#portfolio #main-portfolio li:nth-child(9) a {
	background-image: url(../images/yenga.jpg);
}

#portfolio #main-portfolio li:nth-child(10) a {
	background-image: url(../images/kjaer.png);
}

#portfolio #ne-portfolio li:nth-child(1) a {
	background-image: url(../images/oslo-golfsenter-logo.jpg);
}

#portfolio #ne-portfolio li:nth-child(2) a {
	background-image: url(../images/lokalrorleggeren-logo.jpg);
}

#portfolio #ne-portfolio li:nth-child(3) a {
	background-image: url(../images/lvvs-logo.jpg);
}

#portfolio #ne-portfolio li:nth-child(4) a {
	background-image: url(../images/ungarena-logo.jpg);
}

#portfolio #ne-portfolio li:nth-child(5) a {
	background-image: url(../images/tourdegolf.jpg);
}

#portfolio p {
	margin-bottom: 60px;
	font-size: 20px;
}

#contact {
	color: #fff;
	padding: 260px 0;
}

#contact h3 {
	color: #fff;
	font-size: 75px;
}

#contact p {
	margin-bottom: 60px;
	font-size: 20px;
}

#contact ul li {
	display: inline-block;
	text-align: center;
}

#contact ul li a {
	display: block;
	text-decoration: none;
	color: #fff;
	height: 100px;
	margin-right: 35px;
	margin-bottom: 35px;
	position: relative;
	background-repeat: no-repeat;
}

#contact li:nth-child(1) a {
	background-image: url(../images/in.png);
	width: 111px;
}

#contact li:nth-child(2) a {
	background-image: url(../images/fb-white-100.png);
	width: 100px;
}

#contact li:nth-child(3) a {
	background-image: url(../images/mail.png);
	width: 156px;
}

#contact li:nth-child(4) a {
	background-image: url(../images/github.png);
	width: 103px;
}

#contact li:nth-child(5) a {
	background-image: url(../images/twitter.png);
	width: 123px;
	margin-right: 0;
}

#contact li a:hover {
	-webkit-transition: background 200ms ease-in 100ms;
	-moz-transition: background 200ms ease-in 100ms;
	-o-transition: background 200ms ease-in 100ms;
	transition: background 200ms ease-in 100ms;
}

#contact li:nth-child(1) a:hover {
	background-image: url(../images/in-h.png);
	width: 111px;
}

#contact li:nth-child(2) a:hover {
	background-image: url(../images/fb-white-100-h.png);
	width: 100px;
}

#contact li:nth-child(3) a:hover {
	background-image: url(../images/mail-h.png);
	width: 156px;
}

#contact li:nth-child(4) a:hover {
	background-image: url(../images/github-h.png);
	width: 103px;
}

#contact li:nth-child(5) a:hover {
	background-image: url(../images/twitter-h.png);
}

#contact ul li a span {
	display: none;
}


@media (max-width: 967px) {

 	#portfolio ul a span {
 		font-size: 20px;
 	}

 	#portfolio ul li {
 		width: 50%;
 		padding-bottom: 50%;
 	}

 	.card {
 	 	padding-bottom: 50%;
 	 	width: 50%;
 	}
}


@media (max-width: 779px) {

 	#contact ul li a {
 		height: 50px;
 		margin-right: 10px;
 		margin-bottom: 10px;
 	}

 	#contact li:nth-child(1) a {
 		background-size: 55px;
 		width: 55px;
 	}

 	#contact li:nth-child(2) a {
 		background-size: 50px;
 		width: 50px;
 	}

 	#contact li:nth-child(3) a {
 		background-size: 78px;
 		width: 78px;
 	}

 	#contact li:nth-child(4) a {
 		background-size: 51px;
 		width: 51px;
 	}

 	#contact li:nth-child(5) a {
 		background-size: 61px;
 		width: 61px;
 	}

 	#contact li:nth-child(1) a:hover {
 		background-size: 55px;
 		width: 55px;
 	}

 	#contact li:nth-child(2) a:hover {
 		background-size: 50px;
 		width: 50px;
 	}

 	#contact li:nth-child(3) a:hover {
 		background-size: 78px;
 		width: 78px;
 	}

 	#contact li:nth-child(4) a:hover {
 		background-size: 51px;
 		width: 51px;
 	}

 	#contact li:nth-child(5) a:hover {
 		background-size: 61px;
 		width: 61px;
 	}
}

@media (max-width: 612px) {

 	#portfolio ul li {
 		width: 100%;
		padding-bottom: 100%;
 	}

 	#portfolio ul a span {
 	    font-size: 20px;
 	}

 	.card {
 		padding-bottom: 100%;
 		width: 100%;
 	}
}

@media (max-width: 492px) {

	#slogan h1 {
		font-size: 30px;
	}

 	#portfolio h4 {
 		font-size: 50px;
 	}

 	#contact h3 {
 		font-size: 50px;
 	}
}
